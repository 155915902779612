import { useState } from 'react';
import { toast } from 'react-toastify';

const useAxios = () =>{
  const [response, setResponse] = useState([]);
  const [responseData, setResponseData] = useState([]);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const axiosFetch = async (configObj) =>{
    const {
      axiosInstance,
      method,
      url,
      data
    } = configObj;

    try {
      setLoading(true);
      const res = await axiosInstance[method.toLowerCase()](url,data);
      setResponse(res.data);
      if (res.data.results) {
        setResponseData(res.data.results)
      }else{
        setResponseData(res.data.data.results)
      }
    } catch (error) {
      let resError = error.response
      if(resError?.status === 401 || resError?.status === 403){
        toast.error(resError?.data.detail)
      }
      if (resError?.status === 400) {
        toast.error(resError?.data.message)
      }
      setError(resError);
    } finally {
      setLoading(false);
    }
    if (error) {
      if (error.data.code === "token_not_valid") {
        toast.error("Token is invalid or expired");
      }
    }
    
  }

  return [response, error, loading, axiosFetch, responseData]
}

export default useAxios