import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import AuthContext from '../../context/authProvider';
import Loader from '../../hocs/Loader';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const AdminLogin = () => {
  const { register, handleSubmit, formState:{errors}} = useForm();
  const navigate = useNavigate();
  const {setAuth} = useContext(AuthContext);
  const [ loading, setLoading ] = useState(false);

  const onSubmit = async (data) => {
    try {
      setLoading(true)
      const response = await axios.post(
        `${API_ENDPOINT}/api/login`,
        data,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json' 
          }
        }
      );
      const accessToken = response?.data?.access;
      const refreshToken = response?.data?.refresh;
      const username = response?.data?.name;
      localStorage.setItem('token',refreshToken);
      localStorage.setItem('user',username);
      setAuth({accessToken, refreshToken});
      setLoading(false)
      toast.success('Successfully Logged In');
      navigate('/administrator/dashboard', {replace: true});
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const errorResponse = error.response.data.detail;
        toast.error(errorResponse);
      }
    }
  };

  
  return (
    <>
      {loading && <Loader/>}
      <section className="flex items-center justify-center md:pt-12 md:px-4 sm:px-0 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white md:p-10 p-6 rounded-md">
          <h1 className="text-gray-700 text-4xl font-medium text-center">Admin login</h1>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-8 max-w-md ">
              <div className="grid grid-cols-1 pb-6">
                <label className="relative">
                  <input 
                    type="email" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.email?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                    placeholder="Email Address"       
                    {...register('email',{required:true, pattern: /^\S+@\S+$/i})}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Email Address</span>
                  {errors.email && <p className="text-red-600 p-2 font-light">This field is required</p>}
                </label>
              </div>
              <div className="grid grid-cols-1 pb-6">
                <label className="relative">
                  <input 
                    type="password" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.password?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          } 
                    placeholder="Password"
                    {...register('password',{required:true})}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Password</span>
                  {errors.password && <p className="text-red-600 p-2 font-light">This field is required</p>}
                </label>
                <p className="font-normal text-base text-right py-2">
                  <Link 
                    to="/password-reset"
                    className="px-2 
                    text-blue-500 
                    hover:text-blue-700"
                  >
                    Forgot password ?
                  </Link>
                </p>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <button 
                  className="group 
                  relative w-full 
                  flex justify-center 
                  py-2 px-4 
                  border border-transparent 
                  text-lg font-normal 
                  rounded-md text-black 
                  bg-yellow-400 hover:bg-yellow-500 
                  focus:outline-none"
                  type="submit"
                >
                  Login
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  )
};


export default AdminLogin;