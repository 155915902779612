import {useState} from 'react';
import { Outlet } from 'react-router-dom';
import AuthenticatedNavbar from '../components/navigation/AuthenticatedNavbar';

const EventLayout = () => {
  const [sideNavClose, setSideNavClose] = useState(false);

  const sideNavAction = () => {
    setSideNavClose(!sideNavClose);
  }

  return (
    <main  className="relative md:flex flex-col h-screen">
      <div className="flex h-16 sticky top-0 z-10">
        <AuthenticatedNavbar handleSideNav={sideNavAction}/>
      </div>
      <div className={`relative ${sideNavClose?'md:flex':'flex'} flex-1 overflow-hidden`}>
        
        <div className="flex flex-1">
          <div className="flex-1 overflow-auto p-10">
            <Outlet/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default EventLayout;