import { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import useAuthAxios from "../../hooks/useAuthAxios";
import useAxios from "../../hooks/useAxios";
import Modal from "../../utils/Modal";
import { format } from "date-fns";
import { toast } from "react-toastify";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../utils/Pagination";
import ChevronLeft from "../../utils/ChevronLeft";
import ChevronRight from "../../utils/ChevronRight";
import IF from "../../utils/IF";
import AddIcon from "../../utils/AddIcon";
import ChevronDoubleLeft from "../../utils/ChevronDoubleLeft";
import ChevronDoubleRight from "../../utils/ChevronDoubleRight";
import SearchIcon from "../../utils/SearchIcon";
import Select from "react-select";
import Loader from "../../hocs/Loader";

const SmsContent = () => {
  const compname = "Text Message";
  const app_url = "sms-contents";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm();
  const [sendtoTypes, setSendtoTypes] = useState([]);
  const [charBalance, setCharBalance] = useState(160);
  const [smsCount, setSmsCount] = useState(0);
  const [loadingSpin, setLoadingSpin] = useState(false);

  const event_id = localStorage.getItem("id");
  const [pageSize, setPageSize] = useState({
    value: 10,
    label: 10,
  });

  const smsGroups = [
    { value: "Committee", label: "Committee" },
    { value: "Contributor", label: "Contributor" },
  ];

  const pageSizeOptions = [
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 100, label: 100 },
  ];

  const onChangePageSize = (e) => {
    setPageSize(e);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${e.value}&search=${search}&event=${event_id}`,
    });
  };

  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&search=${search}&event=${event_id}`,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const resetForm = () => {
    reset({
      sms_body: "",
      sms_group: "",
    });
    setOpen(false);
    setCharBalance(160);
    setSmsCount(0);
  };

  const onSubmit = (data) => {
    data.event = event_id;
    const url = `${app_url}`;
    const method = "POST";
    postData(data, url, method);
  };

  const fetchPage = (pageNumber) => {
    const new_url = `${app_url}?page=${pageNumber}&page_size=${pageSize.value}&search=${search}&event=${event_id}`;

    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${new_url}`,
    });
  };

  const postData = async (data, url, method) => {
    setLoadingSpin(true);
    await axios[method.toLowerCase()](url, data).then(
      (result) => {
        setLoadingSpin(false);
        toast.success(result.data.message);
        resetForm();
        getData();
      },
      (error) => {
        setLoadingSpin(false);
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(error.response.data.message);
        }
      }
    );
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&event=${event_id}&search=${searchValue}`,
    });
  };

  const newGroup = (val) => {
    if (val === "Contributor") {
      setSendtoTypes([
        { value: "All_Contributions", label: "All Contributions" },
        {
          value: "Pending_Contributions_all",
          label: "Pending Contributions(all)",
        },
        {
          value: "Pending_Contributions_card",
          label: "Pending Contributions(card)",
        },
        {
          value: "Pending_Contributions_pledge",
          label: "Pending Contributions(pledge)",
        },
        {
          value: "Partial_Contributions_all",
          label: "Partial Contributions(all)",
        },
        {
          value: "Partial_Contributions_card",
          label: "Partial Contributions(card)",
        },
        {
          value: "Partial_Contributions_pledge",
          label: "Partial Contributions(pledge)",
        },
        { value: "Paid_Contributions_all", label: "Paid Contributions(all)" },
        { value: "Paid_Contributions_card", label: "Paid Contributions(card)" },
        {
          value: "Paid_Contributions_pledge",
          label: "Paid Contributions(pledge)",
        },
      ]);
    } else {
      setSendtoTypes([{ value: "All Committee", label: "All Committee" }]);
    }
  };

  const wordCount = (e) => {
    const smsLimit = 160;
    const txtAreaImput = e.target.value;
    const inputChar = txtAreaImput.length;
    const count = parseInt(inputChar / smsLimit);
    setSmsCount(count);
    const charBlock = inputChar - smsLimit * smsCount;
    const balance = charBlock >= 0 ? smsLimit - charBlock : smsLimit;
    setCharBalance(balance);
  };

  return (
    <>
      {(loading || loadingSpin) && <Loader />}
      <section
        className="rounded bg-white 
        shadow-md w-full  
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
          <p className="text-red-600 p-2 font-light">
            <code>{`sms balance: ${response.sms_balance}`}</code>
          </p>
        </div>
        <div className="flex justify-end">
          <button
            onClick={() => setOpen(true)}
            title="Add"
            className="m-1 px-2 py-2 border border-green-500 rounded text-sm font-medium text-white bg-green-500 hover:bg-green-600 focus:outline-none "
          >
            <AddIcon />
          </button>
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6">
            <div className="py-4 flex flex-wrap  gap-y-4">
              <div className="flex-auto w-64 md:max-w-sm lg:max-w-sm4">
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
                  Search
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <SearchIcon />
                  </div>
                  <input
                    type="search"
                    className="block p-4 pl-10 
                        w-full text-base text-gray-900
                        border-1 rounded-md border-opacity-50 border-gray-600
                        focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                    required
                  />
                </div>
              </div>
              <div className="flex-auto w-32">
                <div className="flex justify-end">
                  <p className="px-4 text-sm text-gray-600">Rows per page:</p>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      border: 1,
                      colors: {
                        ...theme.colors,
                        text: "gray",
                        primary25: "lightgray",
                        primary: "#4b5563",
                      },
                    })}
                    defaultValue={pageSize}
                    onChange={(pageSize) => {
                      onChangePageSize(pageSize);
                    }}
                    options={pageSizeOptions}
                  />
                </div>
              </div>
            </div>
            {!loading && !error && !responseData.length > 0 && (
              <p>No Data to Display</p>
            )}
            <IF condition={responseData.length > 0}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="text-gray-700 uppercase tracking-wider">
                    <th className="px-4 py-3 text-left text-xs">Date</th>
                    <th className="px-4 py-3 text-left text-xs">Name</th>
                    <th className="px-4 py-3 text-left text-xs">Mobile</th>
                    <th className="px-4 py-3 text-left text-xs">Group</th>
                    <th className="px-4 py-3 text-left text-xs">Send To</th>
                    <th className="px-4 py-3 text-left text-xs">
                      Content/Body
                    </th>
                    <th className="px-4 py-3 text-left text-xs">Count</th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responseData.map((data, i) => (
                    <tr
                      key={i}
                      className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                    >
                      <td className="px-4 py-3">
                        {format(
                          new Date(data.sent_date),
                          "dd-MM-yyyy HH:mm:ss"
                        )}
                      </td>
                      <td className="px-4 py-3">
                        {data.contributor_name ||
                          data.committee_name ||
                          data.service_provider_name}
                      </td>
                      <td className="px-4 py-3">{data.mobile_number}</td>
                      <td className="px-4 py-3">{data.sms_group}</td>
                      <td className="px-4 py-3">{data.sms_send_to_type}</td>
                      <td className="px-4 py-3">{data.sms_body}</td>
                      <td className="px-4 py-3">{data.sms_count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination data={response.data}>
                <div
                  onClick={() => fetchPage(response.data?.first)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-l-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${
                    response.data?.current !== response.data?.first
                      ? "text-gray-500"
                      : "text-gray-200"
                  } 
                  ${
                    response.data?.current !== response.data?.first
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeft />
                </div>
                <div
                  onClick={() => fetchPage(response.data?.current - 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${
                    response.data?.previous ? "text-gray-500" : "text-gray-200"
                  } 
                  ${
                    response.data?.previous
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft />
                </div>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {response.data?.current} of {response.data?.last}
                </span>
                <div
                  onClick={() => fetchPage(response.data?.current + 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${response.data?.next ? "text-gray-500" : "text-gray-200"}
                  ${
                    response.data?.next ? "hover:bg-gray-100" : "hover:bg-white"
                  }`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight />
                </div>
                <div
                  onClick={() => fetchPage(response.data?.last)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-r-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${
                    response.data?.current !== response.data?.last
                      ? "text-gray-500"
                      : "text-gray-200"
                  }
                  ${
                    response.data?.current !== response.data?.last
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  }`}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRight />
                </div>
              </Pagination>
            </IF>
          </div>
        </div>
        <Modal modalHeader={`New ${compname}`} open={open}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 relative p-6">
              <div className="grid md:grid-cols-2 grid-cols-1 pb-4 gap-6">
                <label className="relative">
                  <Controller
                    name="sms_group"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          border: 1,
                          colors: {
                            ...theme.colors,
                            text: "gray",
                            primary25: "lightgray",
                            primary: "#4b5563",
                          },
                        })}
                        classNamePrefix="mySelect"
                        options={smsGroups}
                        value={
                          smsGroups.find((c) => c.value === field.value) || ""
                        }
                        onChange={(val) => {
                          field.onChange(val.value);
                          newGroup(val.value);
                        }}
                        className={`text-gray-700 text-base font-light w-full
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.sms_group
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                      />
                    )}
                  />
                  <span
                    className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-600
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                  >
                    Group
                  </span>
                  {errors.sms_group && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
                <label className="relative">
                  <Controller
                    name="sms_send_to_type"
                    control={control}
                    defaultValue={null}
                    rules={{ required: true }}
                    render={({ field }) => (
                      <Select
                        theme={(theme) => ({
                          ...theme,
                          borderRadius: 6,
                          border: 1,
                          colors: {
                            ...theme.colors,
                            text: "gray",
                            primary25: "lightgray",
                            primary: "#4b5563",
                          },
                        })}
                        classNamePrefix="mySelect"
                        isDisabled={sendtoTypes.length === 0}
                        options={sendtoTypes}
                        value={
                          sendtoTypes.find((c) => c.value === field.value) || ""
                        }
                        onChange={(val) => field.onChange(val.value)}
                        className={`text-gray-700 text-base font-light w-full
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.sms_send_to_type
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                      />
                    )}
                  />
                  <span
                    className={`
                            placeholder-shown:hidden
                          focus:text-gray-500 bg-white transform -translate-y-7 -translate-x-4 scale-75 rounded-md
                            text-base text-gray-600
                            text-opacity-80  left-0 top-4
                            mx-2 px-2 absolute 
                            transition duration-200`}
                  >
                    Send To
                  </span>
                  {errors.sms_send_to_type && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
              </div>
              <div className="grid grid-cols-1 pb-4 gap-6">
                <label className="relative">
                  <textarea
                    type="text"
                    rows={5}
                    className={`text-gray-700 text-base font-light w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${
                              errors.sms_body
                                ? "border-red-600 focus:border-red-600"
                                : "border-gray-600"
                            } 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`}
                    placeholder="Messsage"
                    {...register("sms_body", {
                      required: true,
                      onChange: (e) => wordCount(e),
                    })}
                  />
                  <IF condition={smsCount === 0}>
                    <small className="flex justify-end text-gray-500">
                      {charBalance}
                    </small>
                  </IF>
                  <IF condition={smsCount > 0}>
                    <small className="flex justify-end text-gray-500">
                      {charBalance}/{smsCount}
                    </small>
                  </IF>
                  <span
                    className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
                  >
                    Message
                  </span>
                  {errors.sms_body && (
                    <p className="text-red-600 p-2 font-light">
                      This field is required
                    </p>
                  )}
                </label>
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button
                onClick={() => resetForm()}
                type="button"
                className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
              >
                <span>Cancel</span>
              </button>
              <button
                type="submit"
                className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
              >
                <span>{"Create"}</span>
              </button>
            </div>
          </form>
        </Modal>
      </section>
    </>
  );
};
export default SmsContent;
