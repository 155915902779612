import React from 'react'

function EventUsers({users}) {
  return (
    <>
      {users.map((user, i) =>
      <p key={i}>
        {'('+(i+1)+') '+user.name+'['+user.email+' - '+user.role+']'}
      </p>
      )}
    </>
  )
}

export default EventUsers