import {authAxios} from '../apis/authAxios';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';
import useRefresh from './useRefresh';
import useAuth from './useAuth';

const useAuthAxios = () =>{
  const refresh = useRefresh();
  const navigate = useNavigate();
  const {auth} = useAuth();

  useEffect(() => {
    const requestInterceptor = authAxios.interceptors.request.use(
      
      config =>{
        if (!config.headers['Authorization']) {
          config.headers['Authorization'] = `JWT ${auth?.accessToken}`;
        }
        return config;
      },(error) =>Promise.reject(error)
    );
    const responseInterceptor = authAxios.interceptors.response.use(
      response => response,
      async (error) => {
        const prevRequest = error?.config;
        if (error?.response?.status === 401) {
          navigate('/login',{replace: true});
        }
        if (error?.response?.status === 403 && !prevRequest.sent) {
          prevRequest.sent = true;
          const newAccessToken = await refresh();
          prevRequest.headers['Authorization'] = `JWT ${newAccessToken}`;
          return authAxios(prevRequest);
        }
        return Promise.reject(error);
      }
    )
    return ()=>{
      authAxios.interceptors.request.eject(requestInterceptor);
      authAxios.interceptors.response.eject(responseInterceptor);
    }
  },[auth, refresh,navigate]);

  return authAxios;
};

export default useAuthAxios;