import React from "react";
import { NavLink } from "react-router-dom";
import { userNavigation } from "../../routes/navigation";

const SideNavbar = ({ handleSideNav }) => {
  const loopNavs = (navs) => {
    return navs.map((r, i) => {
      return (
        <NavLink
          key={i}
          onClick={handleSideNav}
          to={r.path}
          className={`
            inline-flex w-full 
            gap-2 py-4 px-4 
            hover:bg-neutral-600 
            transition 
            duration-200 close-mn`}
        >
          {<r.icon />}
          {r.name}
        </NavLink>
      );
    });
  };
  return (
    <div className="py-7 pl-2">
      <div className="h-5"></div>
      {/* nav links  */}
      <nav>{loopNavs(userNavigation)}</nav>
    </div>
  );
};

export default SideNavbar;
