import React, {useState} from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../hocs/Loader';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Activate = () => {
  const navigate = useNavigate();
  const {uid} = useParams();
  const {token} = useParams();
  const [ loading, setLoading ] = useState(false);

  const verify_account = async(e) => {
    const data = JSON.stringify({uid, token});
    try {
      setLoading(true)
      await axios.post(
        `${API_ENDPOINT}/auth/users/activation/`,
        data,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json' 
          }
        }
      );
      setLoading(false)
      toast.success('Account Successfully Activated');
      navigate('/login', {replace: true});
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const errorResponse = JSON.stringify(error.response.data);
        toast.error(errorResponse);
      }
    }
  };

  return (
    <>
      {loading && <Loader/>}
      <section className="flex items-center justify-center md:pt-12 md:px-4 sm:px-0 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white md:p-10 p-6 rounded-md">
          <h1 className="text-gray-700 text-4xl font-medium text-center">Account activation</h1>
          
          <button 
                  className="group 
                  relative w-full 
                  flex justify-center 
                  py-2 px-4 
                  border border-transparent 
                  text-lg font-normal 
                  rounded-md text-black 
                  bg-yellow-400 hover:bg-yellow-500 
                  focus:outline-none"
                  onClick={verify_account}
                >
                  Activate
                </button>
        </div>
      </section>
    </>
  )
};

export default Activate;