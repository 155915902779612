import React from 'react';
import { Link } from 'react-router-dom';
import homeImage from '../../assets/images/desktop.png'

const Home = () => (
  <>
    <section className="relative lg:p-10 md:p-10 sm:p-4">
      <div className="grid md:grid-cols-2 grid-cols-1">
        <div className="p-5 lg:pt-10 md:pt-10 pt-6">
          <p className="lg:text-7xl md:text-7xl text-5xl lg:font-bold md:font-bold font-normal lg:pt-10 md:pt-5 sm:pt-2">
            Andaa Sherehe Yako Kwa Urahisi
          </p>
          <p className="lg:text-4xl md:text-4xl text-2xl font-thin py-5">
            Ni zaidi ya daftari la mchango
          </p>
          <p className="py-5 pt-10">
            <Link to='/signup' className='
              py-4 px-6 font-normal lg:text-2xl md:text-2xl text-lg text-gray-700 
              bg-yellow-400 rounded hover:bg-yellow-500 
              transition duration-300'>
              Jisajili sasa
            </Link>
          </p>
        </div>
        <div className="p-5">
          <img src={homeImage} alt="" />
        </div>
      </div>
    </section>
  </>
);

export default Home;