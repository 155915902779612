import axios from 'axios';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const BASE_URL = `${API_ENDPOINT}/api/`;

const instance = axios.create({
  baseURL: BASE_URL,  
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
  
});
export default instance;

export const authAxios = axios.create({
  baseURL: BASE_URL,  
  headers: {
    'Content-Type': 'application/json',
    'Accept': 'application/json',
  }
});