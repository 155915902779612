import { useState, useEffect } from "react";
import useRefresh from "../../hooks/useRefresh";
import useAuth from "../../hooks/useAuth";
import { Outlet, useNavigate } from "react-router-dom";

const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefresh();
  const { auth } = useAuth();
  const navigate = useNavigate();

  useEffect(() => {
    const verifyToken = async () => {
      try {
        await refresh();
      } catch (err) {
        if (err?.response.status === 400) {
          navigate("/login", { replace: true });
        }
      } finally {
        setIsLoading(false);
      }
    };
    !auth?.accessToken ? verifyToken() : setIsLoading(false);
  }, [auth, refresh, navigate]);

  return <>{!isLoading && <Outlet />}</>;
};

export default PersistLogin;
