import React from "react";

const CustomBadge = (props) => {
  let color = "";
  switch (props.color) {
    case "red":
      color = "bg-red-50 text-red-700 ring-red-600/10";
      break;
    case "green":
      color = "bg-green-50 text-green-700 ring-green-600/20";
      break;
    case "yellow":
      color = "bg-yellow-50 text-yellow-800 ring-yellow-600/20";
      break;
    case "gray":
      color = "bg-gray-50 text-gray-600 ring-gray-500/10";
      break;
    case "blue":
      color = "bg-blue-50 text-blue-700 ring-blue-700/10";
      break;
    case "indigo":
      color = "bg-indigo-50 text-indigo-700 ring-indigo-700/10";
      break;
    case "purple":
      color = "bg-purple-50 text-purple-700 ring-purple-700/10";
      break;
    case "pink":
      color = "bg-pink-50 text-pink-700 ring-pink-700/10";
      break;

    default:
      break;
  }

  return (
    <span
      className={`inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ${color}`}
    >
      {props.children}
    </span>
  );
};

export default CustomBadge;
