import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

const NotFound = () => {
 const navigate = useNavigate();

 useEffect(() => {
  setTimeout(() => {
   navigate(-1)
  }, 2000)
  // eslint-disable-next-line
 },[])
 return (
  <>
   <section className="flex items-center justify-center bg-white pt-60 px-4 sm:px-6 lg:px-8">
    <div className="max-w-max w-full space-y-8">
     <h1 className="text-gray-500 lg:text-8xl md:text-8xl sm:text-6xl font-semibold text-center">UH OH! You're lost.</h1>
     <p className="text-gray-700 lg:text-5xl md:text-5xl sm:text-3xl fornt-medium text-center py-4">Error 404: Page Not Found</p>
    </div>
   </section>
  </>
 )
}

export default NotFound