import axios from "axios";
import useAuth from "./useAuth";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function useRefresh() {
  const {setAuth} = useAuth();
  const refreshToken = localStorage.getItem('token');
  
  const refresh = async () => {
    const response = await axios.post(`${API_ENDPOINT}/auth/jwt/refresh`,{
      refresh:refreshToken
    });
    
    setAuth(prev =>{
      return { ...prev, accessToken: response.data.access}
    });

    return response.data.access;
  };

  return refresh;
}

export default useRefresh;