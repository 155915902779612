export default function Pagination({data, children}) {
  return(
    <div className="bg-white px-4 py-3 flex items-center justify-between border-t border-gray-200 sm:px-6">
      <div className=" sm:flex-1 sm:flex sm:items-center sm:justify-between">
        <div>
          <p className="text-sm text-gray-700">
          Showing
          { ' ' }
          <span className="font-medium">{ data.from}</span>
          { ' ' }
          -
          { ' ' }
          <span className="font-medium">{ data.to}</span>
          { ' ' }
          of 
          { ' ' }
          <span className="font-medium">{ data.total}</span>
          { ' ' }
          results
          {' '}
          </p>
        </div>
        <nav className="relative z-0 inline-flex rounded-md shadow-sm -space-x-px" aria-label="Pagination">
          {children}
        </nav>
      </div>
    </div>

  )
}