import MenuDropdown from './MenuDropdown';
import Bars3BottomRightIcon from '../../utils/Bars3BottomRightIcon';

const AuthenticatedNavbar = ({handleSideNav}) => {
  const event_name = localStorage.getItem('event_name')?localStorage.getItem('event_name'):'';
  const currentUser = localStorage.getItem('user');
    
  return (
    <div className="w-full max-w-8xl mx-auto bg-neutral-900 flex-none flex flex-wrap">
      <div className="flex-auto h-18 flex items-center justify-between px-4 sm:px-6 lg:mx-6 lg:px-0 xl:mx-8">
        <div className="font-medium flex items-center 
        space-x-3 sm:space-x-4 
        transition-colors duration-200 
        w-full py-2 text-yellow-400">
          <div className='md:hidden flex items-center pr-8'>
            <button className='outline-none' onClick={handleSideNav}>
              <Bars3BottomRightIcon/>
            </button>
          </div>
          {`${event_name}`}
        </div>
        
        <div className="lg:w-64 pl-8 flex-shrink-0 flex items-center justify-end space-x-6">
          <MenuDropdown user={currentUser}/>
        </div>
      </div>
    </div>
  )
}

export default AuthenticatedNavbar