import React, { Fragment } from 'react';
import { Link, NavLink } from 'react-router-dom';

const Navbar = () => {
	const guestLinks = () => (
		<Fragment>
			{/* <NavLink to='/' className='
				py-2 px-2 font-normal text-lg text-white
			 hover:text-gray-200 hover:border-gray-200
				hover:border-b-2 transition duration-300'>
				Product
			</NavLink>
			<NavLink to='/' className='
				py-2 px-2 font-normal text-lg text-white
			 hover:text-gray-200 hover:border-gray-200 
				hover:border-b-2 transition duration-300'>
				Price
			</NavLink>
			<NavLink to='/' className='
				py-2 px-2 font-normal text-lg text-white
			 hover:text-gray-200 hover:border-gray-200 
				hover:border-b-2 transition duration-300'>
				Tutotial
			</NavLink> */}
		</Fragment>
	);
	const actionLinks = () => (
		<Fragment>
			<NavLink to='/login' className='
				py-2 px-2 font-normal text-lg text-white
			 hover:text-gray-200 hover:border-gray-200 
				hover:border-b-2 transition duration-300'>
				Login
			</NavLink>
			<Link to='/signup' className='
				py-2 px-4 font-normal text-lg text-gray-700 
				bg-yellow-400 rounded hover:bg-yellow-500 
				transition duration-300'>
				Signup
			</Link>
		</Fragment>
	);

	return (
  <nav className='w-full max-w-8xl md:px-20 sm:px-5 mx-auto bg-neutral-900 flex-none flex flex-wrap'>
			<div className='flex-auto h-18 flex items-center justify-between lg:mx-6 xl:mx-8'>
				<div className='flex w-full py-2'>
					<Link to='/' className='flex items-center py-4 px-4'>
						{/* <img src='logo.png' alt='Logo' className='h-8 w-8 mr-2'/> */}
						<span className='font-semibold text-gray-200 text-xl'>MPANGO</span>
					</Link>
					
				</div>
				<div className='lg:w-64 pl-8 flex-shrink-0 flex items-center justify-end space-x-2'>
					{ guestLinks() }
				</div>
				<div className='lg:w-64 px-4 flex-shrink-0 flex items-center justify-end space-x-6'>
					{ actionLinks() }
				</div>
			</div>
  </nav>
	);
};


export default Navbar;