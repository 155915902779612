import React from "react";
import IF from "./IF";
import { useState } from "react";
import { useForm } from "react-hook-form";
import useAuthAxios from "../hooks/useAuthAxios";
import { toast } from "react-toastify";

const Sms = ({ recipientData, closeModal, loader }) => {
  const app_url = "sms-contents";
  const axios = useAuthAxios();
  // eslint-disable-next-line
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const [charBalance, setCharBalance] = useState(160);
  const [smsCount, setSmsCount] = useState(0);

  const event_id = localStorage.getItem("id");

  const resetForm = () => {
    reset({
      sms_body: "",
    });
    setCharBalance(160);
    setSmsCount(0);
    closeModal();
  };

  const wordCount = (e) => {
    const smsLimit = 160;
    const txtAreaImput = e.target.value;
    const inputChar = txtAreaImput.length;
    const count = parseInt(inputChar / smsLimit);
    setSmsCount(count);
    const charBlock = inputChar - smsLimit * smsCount;
    const balance = charBlock >= 0 ? smsLimit - charBlock : smsLimit;
    setCharBalance(balance);
  };

  const onSubmit = (data) => {
    data.event = event_id;
    data.recipient_id = recipientData.id;
    data.sms_group = recipientData.group;
    data.sms_send_to_type = recipientData.send_to_type;
    const url = `${app_url}`;
    const method = "POST";
    postData(data, url, method);
  };

  const postData = async (data, url, method) => {
    loader(true);
    await axios[method.toLowerCase()](url, data).then(
      (result) => {
        loader(false);
        if (result.status === 201) {
          toast.success(result.data.message);
          resetForm();
        }
      },
      (error) => {
        loader(false);
        const errorData = JSON.stringify(error.response.data);
        if (error.response.data.message) {
          toast.error(error.response.data.message);
        } else {
          toast.error(errorData);
        }
      }
    );
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mt-4 relative p-6">
        <div className="grid grid-cols-1 pb-4 gap-6">
          <label className="relative">
            <textarea
              type="text"
              rows={5}
              className={`text-gray-700 text-base font-light w-full px-4
                  border-1 rounded-md border-opacity-50 outline-none
                  ${
                    errors.sms_body
                      ? "border-red-600 focus:border-red-600"
                      : "border-gray-600"
                  } 
                  focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                  transition duration-200`}
              placeholder="Message"
              {...register("sms_body", {
                required: true,
                onChange: (e) => wordCount(e),
              })}
            />
            <span
              className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200"
            >
              Message
            </span>
            <IF condition={smsCount === 0}>
              <small className="flex justify-end text-gray-500">
                {charBalance}
              </small>
            </IF>
            <IF condition={smsCount > 0}>
              <small className="flex justify-end text-gray-500">
                {charBalance}/{smsCount}
              </small>
            </IF>
            {errors.sms_body && (
              <p className="text-red-600 p-2 font-light">
                This field is required
              </p>
            )}
          </label>
        </div>
      </div>
      <div className="flex py-4 px-6 justify-end">
        <button
          onClick={() => resetForm()}
          type="button"
          className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm"
        >
          <span>Cancel</span>
        </button>
        <button
          type="submit"
          className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-black bg-yellow-400 hover:bg-yellow-500 focus:outline-none sm:text-sm"
        >
          <span>{"Create"}</span>
        </button>
      </div>
    </form>
  );
};

export default Sms;
