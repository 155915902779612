import { useEffect, useState } from "react";
import useAuthAxios from "../../hooks/useAuthAxios";
import useAxios from "../../hooks/useAxios";
import Modal from "../../utils/Modal";
import Sms from "../../utils/Sms";

import "react-datepicker/dist/react-datepicker.css";
import Pagination from "../../utils/Pagination";
import ChatIcon from "../../utils/ChatIcon";
import ChevronLeft from "../../utils/ChevronLeft";
import ChevronRight from "../../utils/ChevronRight";
import IF from "../../utils/IF";
import ChevronDoubleLeft from "../../utils/ChevronDoubleLeft";
import ChevronDoubleRight from "../../utils/ChevronDoubleRight";
import SearchIcon from "../../utils/SearchIcon";
import Select from "react-select";
import Loader from "../../hocs/Loader";

const AdminUser = () => {
  const compname = "User";
  const app_url = "all-users";
  const axios = useAuthAxios();
  const [response, error, loading, axiosFetch, responseData] = useAxios();
  const [search, setSearch] = useState("");
  const [openSms, setOpenSms] = useState(false);
  const [recipientData, setRecipientData] = useState({});

  const [pageSize, setPageSize] = useState({
    value: 10,
    label: 10,
  });

  const pageSizeOptions = [
    { value: 10, label: 10 },
    { value: 15, label: 15 },
    { value: 20, label: 20 },
    { value: 30, label: 30 },
    { value: 100, label: 100 },
  ];

  const onChangePageSize = (e) => {
    setPageSize(e);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${e.value}&search=${search}`,
    });
  };

  const getData = () => {
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&search=${search}`,
    });
  };

  useEffect(() => {
    getData();
    // eslint-disable-next-line
  }, []);

  const fetchPage = (pageNumber) => {
    const new_url = `${app_url}?page=${pageNumber}&page_size=${pageSize.value}&search=${search}`;

    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${new_url}`,
    });
  };

  const handleSearch = (e) => {
    const searchValue = e.target.value.toLowerCase();
    setSearch(searchValue);
    axiosFetch({
      axiosInstance: axios,
      method: "GET",
      url: `${app_url}?page_size=${pageSize.value}&search=${searchValue}`,
    });
  };

  const sendSms = (id) => {
    setRecipientData({
      id: id,
      group: "Admin",
      send_to_type: "Individual",
    });
    setOpenSms(true);
  };

  const closeSmsModal = () => {
    setOpenSms(false);
  };

  return (
    <>
      {loading && <Loader />}
      <section
        className="rounded bg-white 
        shadow-md w-full 
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6">
            <div className="py-4 flex flex-wrap  gap-y-4">
              <div className="flex-auto w-64 md:max-w-sm lg:max-w-sm4">
                <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-gray-300">
                  Search
                </label>
                <div className="relative">
                  <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                    <SearchIcon />
                  </div>
                  <input
                    type="search"
                    className="block p-4 pl-10 
                        w-full text-base text-gray-900
                        border-1 rounded-md border-opacity-50 border-gray-600
                        focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1"
                    placeholder="Search..."
                    onChange={(e) => handleSearch(e)}
                    required
                  />
                </div>
              </div>
              <div className="flex-auto w-32">
                <div className="flex justify-end">
                  <p className="px-4 text-sm text-gray-600">Rows per page:</p>
                  <Select
                    theme={(theme) => ({
                      ...theme,
                      borderRadius: 6,
                      border: 1,
                      colors: {
                        ...theme.colors,
                        text: "gray",
                        primary25: "lightgray",
                        primary: "#4b5563",
                      },
                    })}
                    defaultValue={pageSize}
                    onChange={(pageSize) => {
                      onChangePageSize(pageSize);
                    }}
                    options={pageSizeOptions}
                  />
                </div>
              </div>
            </div>
            {!loading && !error && !responseData.length > 0 && (
              <p>No Data to Display</p>
            )}
            <IF condition={responseData.length > 0}>
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                  <tr className="text-gray-700 uppercase tracking-wider">
                    <th className="px-4 py-3 text-left text-xs">Name</th>
                    <th className="px-4 py-3 text-left text-xs">Mobile</th>
                    <th className="px-4 py-3 text-left text-xs">Email</th>
                    <th className="px-4 py-3 text-left text-xs">Role</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {responseData.map((data, i) => (
                    <tr
                      key={i}
                      className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                    >
                      <td className="px-4 py-3">{data.name}</td>
                      <td className="px-4 py-3">{data.mobile}</td>
                      <td className="px-4 py-3">{data.email}</td>
                      <td className="px-4 py-3">{data.role}</td>
                      <td>
                        <button
                          onClick={() => sendSms(data.id)}
                          title="Send SMS"
                          type="button"
                          className="m-1 px-2 py-2 border 
                        border-green-500 rounded 
                        text-sm font-medium 
                        text-white bg-green-500 
                        hover:bg-green-600 focus:outline-none "
                        >
                          <ChatIcon />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Pagination data={response}>
                <div
                  onClick={() => fetchPage(response.first)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-l-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${
                    response.current !== response.first
                      ? "text-gray-500"
                      : "text-gray-200"
                  } 
                  ${
                    response.current !== response.first
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">First</span>
                  <ChevronDoubleLeft />
                </div>
                <div
                  onClick={() => fetchPage(response.current - 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium
                  ${response.previous ? "text-gray-500" : "text-gray-200"} 
                  ${
                    response.previous ? "hover:bg-gray-100" : "hover:bg-white"
                  } `}
                >
                  <span className="sr-only">Previous</span>
                  <ChevronLeft />
                </div>
                <span className="relative inline-flex items-center px-4 py-2 border border-gray-300 bg-white text-sm font-medium text-gray-700">
                  Page {response.current} of {response.last}
                </span>
                <div
                  onClick={() => fetchPage(response.current + 1)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${response.next ? "text-gray-500" : "text-gray-200"}
                  ${response.next ? "hover:bg-gray-100" : "hover:bg-white"}`}
                >
                  <span className="sr-only">Next</span>
                  <ChevronRight />
                </div>
                <div
                  onClick={() => fetchPage(response.last)}
                  className={`relative inline-flex 
                  items-center px-2 py-2 rounded-r-md 
                  border border-gray-300 bg-white text-sm 
                  font-medium 
                  ${
                    response.current !== response.last
                      ? "text-gray-500"
                      : "text-gray-200"
                  }
                  ${
                    response.current !== response.last
                      ? "hover:bg-gray-100"
                      : "hover:bg-white"
                  }`}
                >
                  <span className="sr-only">Last</span>
                  <ChevronDoubleRight />
                </div>
              </Pagination>
            </IF>
          </div>
        </div>
        <Modal modalHeader={`Send Text Message`} open={openSms}>
          <Sms recipientData={recipientData} closeModal={closeSmsModal} />
        </Modal>
      </section>
    </>
  );
};
export default AdminUser;
