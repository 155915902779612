export const NumberWithCommas = inputNumber =>{
  if (inputNumber !== undefined) {
    return NumberWithoutCommas(inputNumber).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return ;
};

export const NumberWithoutCommas = inputNumber =>{
  if (inputNumber !== undefined) {
    return inputNumber.toString().replace(/,/g, '');
  }
  return ;
};