import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup'
import * as Yup from 'yup'
import axios from 'axios';
import { toast } from 'react-toastify';
import Loader from '../../hocs/Loader';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const PasswordResetConfirm = () => {
  const {uid} = useParams();
  const {token} = useParams();
  const passFormat = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[\S]{8,}$/;
  const formSchema = Yup.object().shape({
    new_password: Yup.string()
      .required('This field is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(passFormat,'Password should contain at least one uppercase letter, lowercase letter, digit, and special symbol.'),
    re_new_password: Yup.string()
      .required('This field is required')
      .oneOf([Yup.ref('new_password')],'Passwords does not match'),
  });
  const formOptions = {resolver: yupResolver(formSchema)};
  const { register, handleSubmit, formState:{errors}} = useForm(formOptions);
  const navigate = useNavigate();
  const [ loading, setLoading ] = useState(false);
 
  const onSubmit = async(data) => {
    data.uid = uid;
    data.token = token;
    
    try {
      setLoading(true)
      await axios.post(
        `${API_ENDPOINT}/auth/users/reset_password_confirm/`,
        data,
        {
          headers: { 
            'Content-Type': 'application/json',
            'Accept': 'application/json' 
          }
        }
      );
      setLoading(false)
      toast.success('Password Successfully Reseted');
      navigate('/login', {replace: true});
    } catch (error) {
      setLoading(false)
      if (error?.response) {
        const errorResponse = JSON.stringify(error.response.data);
        toast.error(errorResponse);
      }
    }
  };

  return (
    <>
      {loading && <Loader/>}
      <section className="flex items-center justify-center md:pt-12 md:px-4 sm:px-0 lg:px-8">
        <div className="max-w-md w-full space-y-8 bg-white md:p-10 p-6 rounded-md">
          <h1 className="text-gray-700 text-4xl font-medium text-center">Reset password</h1>
          
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-8 max-w-md">
              <div className="grid grid-cols-1 pb-4">
                <label className="relative">
                  <input 
                    type="password" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.new_password?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                    placeholder="New password"
                    {...register('new_password')}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">New password</span>
                  {errors.new_password && <p className="text-red-600 p-2 font-light">{errors.new_password?.message}</p>}
                </label>
              </div>
              <div className="grid grid-cols-1 pb-4">
                <label className="relative">
                  <input 
                    type="password" 
                    className={
                            `text-gray-700 text-base font-light h-12 w-full px-4
                            border-1 rounded-md border-opacity-50 outline-none
                            ${errors.re_new_password?'border-red-600 focus:border-red-600':'border-gray-600'} 
                            focus:ring-0 focus:border-gray-600 focus:text-gray-900 focus: border-1
                            transition duration-200`
                          }
                    placeholder="Confirm password"
                    {...register('re_new_password')}
                  />
                  <span className="
                  text-base text-gray-900
                  text-opacity-80  left-0 top-4
                  mx-2 px-2 absolute input-text
                  transition duration-200">Confirm password</span>
                  {errors.re_new_password && <p className="text-red-600 p-2 font-light">{errors.re_new_password?.message}</p>}
                </label>
              </div>
              <div className="grid grid-cols-1 gap-4">
                <button 
                  className="group 
                  relative w-full 
                  flex justify-center 
                  py-2 px-4 
                  border border-transparent 
                  text-lg font-normal 
                  rounded-md text-black 
                  bg-yellow-400 hover:bg-yellow-500 
                  focus:outline-none"
                  type="submit"
                >
                  Send
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  )
};

export default PasswordResetConfirm;