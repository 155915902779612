import Navbar from '../components/navigation/Navbar';
import { Outlet } from 'react-router-dom';

const Layout = () => {

  return (
    <main className="relative md:flex sm:flex flex-col h-screen">
      <div className="flex h-18 sticky top-0 z-10">
        <Navbar/>
      </div>
      <div className="relative flex flex-1 overflow-hidden">
        <div className="flex flex-1">
          <div className="flex-1 overflow-auto lg:p-10 md:p-10 sm:p-2 bg-orange-50">
            <Outlet/>
          </div>
        </div>
      </div>
    </main>
  );
};

export default Layout;