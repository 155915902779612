import { useContext, useDebugValue } from 'react';
import AuthContext from '../context/authProvider';

function useAuth() {
  const {auth} = useContext(AuthContext);
  useDebugValue(auth, auth => auth?.user? "Logged In":"Logged out");

  return useContext(AuthContext);
}

export default useAuth;