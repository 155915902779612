import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import useAuthAxios from '../../hooks/useAuthAxios';
import Modal from '../../utils/Modal';
import { toast } from 'react-toastify';

import "react-datepicker/dist/react-datepicker.css";
import IF from '../../utils/IF';
import Select from 'react-select';
import DownloadIcon from '../../utils/DownloadIcon';
import fileDownload from 'js-file-download';
import {Buffer} from 'buffer';
import Loader from '../../hocs/Loader';

const Report = () => {
  const compname = "Report";
  const app_url = "reports";
  const axios = useAuthAxios();
  const [open, setOpen] = useState(false);
  const [moduleName, setModuleName] = useState('');
  const [exportType, setExportType] = useState('');
  // eslint-disable-next-line
  const { register, handleSubmit, reset, setValue, control, formState:{errors}} = useForm();
  const [ loadingSpin, setLoadingSpin ] = useState(false);
  
  const event_id = localStorage.getItem('id');

  const reportData = [
    { id: 1, name: 'Committee', url_name:'committees'},
    { id: 2, name: 'Budget', url_name:'budgets'},
    { id: 3, name: 'Contribution', url_name:'contributions'},
  ];

  const contributionGroups = [
        {value:"All_Contributions", label: "All Contributions"},
        {value:"Pending_Contributions_all", label: "Pending Contributions(all)"},
        {value:"Pending_Contributions_card", label: "Pending Contributions(card)"},
        {value:"Pending_Contributions_pledge", label: "Pending Contributions(pledge)"},
        {value:"Partial_Contributions_all", label: "Partial Contributions(all)"},
        {value:"Partial_Contributions_card", label: "Partial Contributions(card)"},
        {value:"Partial_Contributions_pledge", label: "Partial Contributions(pledge)"},
        {value:"Paid_Contributions_all", label: "Paid Contributions(all)"},
        {value:"Paid_Contributions_card", label: "Paid Contributions(card)"},
        {value:"Paid_Contributions_pledge", label: "Paid Contributions(pledge)"},
      ]
  
  const resetForm = () => {
    reset({
      group:"",
    });
    setOpen(false);
    setExportType('');
    setModuleName('');
  };

  const onSubmit = (data) => {
    data.event = event_id;
    data.name = (data.name)?data.name:moduleName;
    data.export_type = (data.export_type)?data.export_type:exportType;
    const url = `${app_url}/${data.name.toLowerCase()}`;
    const method = 'POST';
    postData(data,url,method );
  }

  const postData = async (data,url,method ) => {
    setLoadingSpin(true);
    data.responseType = 'blob';
    await axios[method.toLowerCase()](url,data).then(
      (result)=>{
        setLoadingSpin(false);
        const filename = data.name+'.'+data.export_type;
        if (data.export_type === 'xlsx') {
          const buff = Buffer.from(result.data, 'base64');
          let new_file =  new File([buff], filename, {type:'text/excel'});
          fileDownload(new_file, filename);
        } else {
          fileDownload(result.data, filename);
        }
        toast.success(result.data.message)
        resetForm();
      },
      (error)=>{
        setLoadingSpin(false);
        if (error.response.status === 403) {
          toast.error(error.response.data.detail);
        } else {
          toast.error(error.response.data.message);
        }
      }
    );
  }

  const pdfData =  (data)=>{
    setExportType('pdf')
    setModuleName(data.name)
    if (data.name === "Contribution") {
      setOpen(true)
    } else {
      data.export_type = 'pdf';
      onSubmit(data)
    }
  }

  const xlsData =  (data)=>{
    setExportType('xlsx')
    setModuleName(data.name)
    if (data.name === "Contribution") {
      setOpen(true)
    } else {
      data.export_type = 'xlsx';
      onSubmit(data)
    }
  }

    
  return (
    <>
      {(loadingSpin) && <Loader/>}
      <section 
        className="rounded bg-white 
        shadow-md w-full  
        px-4 sm:px-6 lg:px-8 py-4"
      >
        <div className="mx-auto">
          <h1 className="text-3xl font-normal text-gray-900">
            {`${compname}s`}
          </h1>
        </div>
        <div>
          <div className="overflow-x-auto sm:-mx-4 lg:-mx-8 p-6">
            <IF condition={reportData.length > 0}>
              <table  className="min-w-full divide-y divide-gray-200">
                <thead  className="bg-gray-50">
                  <tr className="text-gray-700 uppercase tracking-wider">
                    <th className="px-4 py-3 text-left text-xs">Name</th>
                    <th className="px-4 py-3 text-left text-xs">XLS</th>
                    <th className="px-4 py-3 text-left text-xs">PDF</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {reportData.map((data, i) =>
                  <tr key={i}  
                    className="whitespace-no-wrap
                    text-sm text-gray-600 py-4"
                  >
                    <td className="px-4 py-3">{ data.name }</td>
                    <td className="px-4 py-3">
                      <button 
                        onClick={() => xlsData(data)} 
                        title="Export to Excel" 
                        type="button" 
                        className="m-1 px-2 py-2 border 
                        border-green-500 rounded 
                        text-sm font-medium 
                        text-white bg-green-500 
                        hover:bg-green-600 focus:outline-none "
                      >
                        <DownloadIcon/>
                      </button>
                      </td>
                    <td className="px-4 py-3">
                      <button 
                        onClick={() => pdfData(data)} 
                        title="Export to PDF" 
                        type="button" 
                        className="m-1 px-2 py-2 border 
                        border-gray-500 rounded 
                        text-sm font-medium 
                        text-white bg-gray-500 
                        hover:bg-gray-600 focus:outline-none "
                      >
                        <DownloadIcon/>
                      </button>
                    </td>
                  </tr>)}
                </tbody>
              </table>
            </IF>
            
          </div>
        </div>
        <Modal 
          modalHeader={`Select Contribution Group`} 
          open={open}
        >
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="mt-4 relative p-6">
              <div className="grid grid-cols-1 pb-4 gap-6">
                <label>
                  <span className="text-gray-500 text-sm">Group</span>
                  <Controller
                    name="group"
                    control={control}
                    defaultValue={null}
                    rules={{required:true}}
                    render={
                      ({field})=>(
                        <Select 
                          isDisabled={contributionGroups.length === 0}
                          options={contributionGroups}
                          value={contributionGroups.find(c => c.value === field.value) || ''}
                          onChange={val => field.onChange(val.value)}
                          className={
                            `text-gray-700
                            block
                            w-full
                            px-0.5
                            ${errors.group?'border-red-600 focus:border-red-600':'border-gray-200'} 
                            focus:ring-0 focus:border-black`
                          } 
                        />
                      ) 
                    }
                  />
                  {errors.group && <p className="text-red-600">This field is required</p>}
                </label>
              </div>
            </div>
            <div className="flex py-4 px-6 justify-end">
              <button onClick={()=>resetForm()} type="button" className="m-1 py-2 px-4 border-0 border-transparent text-md font-medium rounded-md border-gray-300 text-gray-700 hover:bg-gray-50 focus:outline-none sm:text-sm">
                  <span>Cancel</span>
              </button>
              <button type="submit" className="m-1 py-2 px-4 border border-transparent text-md font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none sm:text-sm">
                  <span>{'Create'}</span>
              </button>
            </div>
          </form>
        </Modal>
      </section>
    </>
  )
};
export default Report;