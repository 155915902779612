import {useState} from 'react';
import { Outlet } from 'react-router-dom';
import AuthenticatedNavbar from '../components/navigation/AuthenticatedNavbar';
import SideNavbar from '../components/navigation/SideNavbar';

const AuthenticatedLayout = () => {
  const [sideNavClose, setSideNavClose] = useState(false);

  const sideNavAction = () => {
    setSideNavClose(!sideNavClose);
  }

  return (
    <main className="relative md:flex flex-col h-screen">
      <div className="flex h-16 sticky top-0 z-10">
        <AuthenticatedNavbar handleSideNav={sideNavAction}/>
      </div>
      <div className={`relative ${sideNavClose?'md:flex':'flex'} flex-1 overflow-hidden`}>
        <div className={`
          ${sideNavClose?'hidden':''}
          bg-neutral-700 text-gray-50 shadow-md
          flex-none md:hidden w-52
          transition duration-200 ease-in-out`}
        >
          <SideNavbar handleSideNav={sideNavAction}/>
        </div>
        <div className={`
          inset-0 flex-none lg:h-auto lg:pt-0 
          lg:w-60 xl:w-72 lg:block hidden
          bg-neutral-700 text-gray-50 shadow-md 
          transform -translate-x-full md:translate-x-0
          transition duration-200 ease-in-out`}
        >
          <SideNavbar/>
        </div>
        <div className="flex flex-1">
          <div className="flex-1 overflow-auto p-10 bg-gray-200">
            <Outlet/>
          </div>
        </div>
      </div>
    </main>
  )
}

export default AuthenticatedLayout