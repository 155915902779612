import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import ChevronDownIcon from '../../utils/ChevronDownIcon';
import ArrowRightOnRectangleIcon from '../../utils/ArrowRightOnRectangleIcon';

const MenuDropdown = ({user}) => {
  const [showOption, setShowOption] = useState(false);
  const navigate = useNavigate();
  const {setAuth} = useAuth();

  const handleDropdown = () => {
    setShowOption(!showOption)
  }

  const handleLogout = () => {
    handleDropdown();
    setAuth({});
    navigate('/login', {replace: true});
    localStorage.clear();
  }

  return (
    <div className="relative inline-block text-left">
  <div>
    <button onClick={handleDropdown} type="button" className="inline-flex w-full justify-center gap-x-1.5 rounded-md px-3 py-2 text-sm font-semibold text-yellow-400 shadow-sm ring-1 ring-inset ring-neutral-900 hover:bg-neutral-700" id="menu-button" aria-expanded="true" aria-haspopup="true">
      {user}
      <ChevronDownIcon size={`w-5 h-5`}/>
    </button>
  </div>
  { showOption && 
    <div className="absolute right-0 z-10 mt-2 w-36 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none" role="menu" aria-orientation="vertical" aria-labelledby="menu-button" tabIndex="-1">
      <div className="py-1" role="none">
        <Link onClick={handleLogout} className="text-gray-700 inline-flex w-full gap-3 px-4 py-2 text-base hover:bg-gray-200" role="menuitem" tabIndex="-1">
          <ArrowRightOnRectangleIcon/> Logout
        </Link>
      </div>
    </div>
  }
</div>
  )
}

export default MenuDropdown