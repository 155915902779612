export default function Modal({ modalHeader, width, open, children}) {
  
  return(
    <div  className={`fixed inset-0 ${open ? '' : 'pointer-events-none'}`}>
      
      {/* backdrop  */}
      <div 
        className={
          `fixed inset-0 bg-black 
          ${open ? 'opacity-70' : 
          'pointer-events-none opacity-0'}
          transition-opacity duration-300 ease-in-out`
        }
       />

      {/* your contents  */}
      <div 
        className={
          `relative top-20 mx-auto w-auto 
          ${width?width:'max-w-screen-sm'} 
          ${open ? 'opacity-100' : 
          'pointer-events-none opacity-0'}
          transition-opacity duration-300 ease-in-out`
        }
      >
        {/* content  */}
        <div className="relative border-0
          flex flex-col w-full 
          shadow-lg rounded-md bg-white
          outline-none focus:outline-none"
        >
          {/* header  */}
          <div className="flex item-start justify-between p-5 
          border-b border-solid border-gray-200 rounded-t bg-neutral-900">
            <h3 className="text-3xl font-normal text-yellow-400">
              {modalHeader} 
            </h3>
          </div>
          {/* body  */}
          {children}
        </div>
      </div>
    </div>
  )
}